import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { Input, DatePicker, Select, Button } from "antd"
import * as classes from '../Table.module.css';
import {
  SearchOutlined,
} from '@ant-design/icons';
import Pagination from "react-js-pagination";
import { CSVLink } from "react-csv";
import moment from "moment";
import { get, isEmpty } from "lodash";
import Loader from "../../components/Loader/Loader";
import TextModal from "../../components/TextModal/TextModal";
import PermissionDeniedModal from "../../components/PermissionDeniedModal/PermissionDeniedModal";
import orderAPI from "../../api/OrderAPI/orderAPI";
import eventAPI from "../../api/EventAPI/eventAPI";
import Layout from "../../components/Layout";
import { logout } from "../../util/auth";
import { checkPermission } from "../../util/helpers";
import { ROLE_VALUES } from "../../util/constants";

const { RangePicker } = DatePicker;
const { Option } = Select;

const INITIAL_FOR_CONTROL_DATA = {
  created_at: "",
  order_code: "",
  buyer_name: "",
  buyer_email: "",
  buyer_phone: "",
  quantity: 0,
  product_name_en: "",
  order_status: "",
  payment_method: "",
  subtotal: "",
  discount_amount: "",
  coupon_codes: "",
  grand_total: "",
  purchased_at: "",
  company_name: "",
  company_address: "",
  company_tax_code: "",
  recipient_name: "",
  recipient_phone: "",
  recipient_address: ""
};

const FOR_CONTROL_DATA_HEADER = [
  ["CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM"],
  ["Độc lập - Tự do - Hạnh phúc"],
  ["GIAO DỊCH ĐỐI SOÁT HOA HỒNG"]
]
const FOR_CONTROL_DATA_LABELS = [
  ["Created At", "Order Code", "Buyer Name", "Buyer Email", "Buyer Phone", "Total Ticket", "Product Name", "Order status", "Payment Method", "Subtotal", "Discount", "Coupon Codes", "Grandtotal", "Purchased At", "Company Name", "Company Address", "Tax Code", "Recipient Name", "Recipient Phone Number", "Recipient Email Address"],
  ["Ngày Tạo Đơn", "Mã Đơn Hàng", "Tên Người Mua", "Email Người Mua", "Số Điện Thoại Người Mua", "Số Lượng Vé", "Tên Sản Phẩm", "Trạng Thái Đơn Hàng", "Phương Thức Thanh Toán", "Tổng Giá Trị Đơn Hàng", "Chiết Khấu", "Mã Ưu Đãi", "Số Tiền Thanh Toán Thực Tế", "Ngày Mua", "Tên Công Ty", "Địa Chỉ Công Ty", "Mã Số Thuế", "Tên Người Nhận", "Số Điện Thoại Người Nhận", "Email Người Nhận"],
]
const Orders = (props) => {
  const { location } = props;
  const { search, pathname } = location;

  const query = new URLSearchParams(search);
  const orderCsvInstance = useRef(null);
  const orderItemsCsvInstance = useRef(null);
  const orderItemsForControlCsvInstance = useRef(null);

  // const pageQuery = query.get('page') || 1;

  const [orderData, setOrderData] = useState({
    orders: [],
    paging: null,
    total: 0
  });
  const [events, setEvents] = useState([]);
  const [ordersExportData, setOrdersExportData] = useState([]);
  const [ordersForControlExportData, setOrdersForControlExportData] = useState([]);
  const [orderItemsExportData, setOrderItemsExportData] = useState([]);

  const [filterData, setFilterData] = useState({
    order_code: query.get('order_code') || '',
    buyer_phone: query.get('buyer_phone') || '',
    event_id: query.get('event_id') || '',
    payment_method: query.get('payment_method') || '',
    order_type: query.get('order_type') || '',
    order_status: query.get('order_status') || '',
    from_date: query.get('from_date') || '',
    to_date: query.get('to_date') || '',
    purchased_from_date: query.get('from_date') || '',
    purchased_to_date: query.get('to_date') || '',
    coupons: query.get('coupons') || '',
    ref_number: query.get('ref_number') || '',
    ui_version: query.get('ui_version') || ''
  })

  const [isLoading, setIsLoading] = useState(false);
  const [isShowPermissionDeniedModal, setIsShowPermissionDeniedModal] = useState(false);
  const [isShowNoDataModal, setIsShowNoDataModal] = useState(false);

  useEffect(() => {
    checkExpiredToken();
    getEvents();
  }, []);

  useEffect(() => {
    setFilterData({
      order_code: query.get('order_code') || '',
      buyer_phone: query.get('buyer_phone') || '',
      event_id: query.get('event_id') || '',
      payment_method: query.get('payment_method') || '',
      order_type: query.get('order_type') || '',
      order_status: query.get('order_status') || '',
      from_date: query.get('from_date') || '',
      to_date: query.get('to_date') || '',
      purchased_from_date: query.get('purchased_from_date') || '',
      purchased_to_date: query.get('purchased_to_date') || '',
      coupons: query.get('coupons') || '',
      ref_number: query.get('ref_number') || '',
      ui_version: query.get('ui_version') || ''
    });
    getOrders(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (!isEmpty(ordersExportData) && orderCsvInstance && orderCsvInstance.current && orderCsvInstance.current.link) {
      setTimeout(() => {
        orderCsvInstance.current.link.click();
        setOrdersExportData([]);
      });
    }
  }, [ordersExportData]);

  useEffect(() => {
    if (!isEmpty(orderItemsExportData) && orderItemsCsvInstance && orderItemsCsvInstance.current && orderItemsCsvInstance.current.link) {
      setTimeout(() => {
        orderItemsCsvInstance.current.link.click();
        setOrderItemsExportData([]);
      });
    }
  }, [orderItemsExportData]);

  useEffect(() => {
    if (!isEmpty(ordersForControlExportData) && orderItemsForControlCsvInstance && orderItemsForControlCsvInstance.current && orderItemsForControlCsvInstance.current.link) {
      setTimeout(() => {
        orderItemsForControlCsvInstance.current.link.click();
        setOrdersForControlExportData([]);
      });
    }
  }, [ordersForControlExportData]);

  const checkExpiredToken = () => {
    const time = localStorage.getItem('time');
    const expiredTime = localStorage.getItem('expiredTime');

    if (!time) {
      const currentTime = moment();
      const exTime = moment().add(60, 'minutes');
      localStorage.setItem('time', currentTime);
      localStorage.setItem('expiredTime', exTime);
    } else {
      const currentTime = moment();

      const expired = moment(currentTime).isBetween(time, expiredTime);

      if (!expired) {
        window.alert('Phiên của bạn đã hết hạn. Vui lòng đăng nhập lại');
        logout();
        localStorage.removeItem('time');
        localStorage.removeItem('expiredTime');
        window.location.href="/login";
      } else {
        const currentTime = moment();
        const exTime = moment().add(60, 'minutes');
        localStorage.setItem('time', currentTime);
        localStorage.setItem('expiredTime', exTime);
      }
    }
  }

  const getOrders = async (page) => {
    setOrdersExportData([]);
    setOrderItemsExportData([]);
    setOrdersForControlExportData([]);
    setIsLoading(true);
    try {
      const response = await orderAPI.getOrders(page, search);
      if (response) {
        setOrderData({
          orders: response.data,
          paging: response.paging,
          total: response.total
        });
      }
      window.scrollTo(0, 0);
      isEmpty(get(response, 'data', [])) && setIsShowNoDataModal(true);
    } catch (error) {
      setIsShowNoDataModal(true);
      setOrderData({
        orders: [],
        paging: null,
        total: 0
      });
      console.log("Faile to fetch", error);
    }
    setIsLoading(false);
  };

  const getEvents = async () => {
    try {
      const response = await eventAPI.getEvents();
      setEvents(response);
    } catch (error) {
      console.log("Faile to fetch", error);
    }
  };

  const _navigateOrderDetail = (code) => {
    props.history.push(`/orders/${code}`);
  };

  const getOrdersExportData = async () => {
    setIsLoading(true);
    try {
      const response = 
        filterData.order_type === 'shopping' 
        ? await orderAPI.getShopOrdersExportData(search)
        : filterData.event_id && await orderAPI.getEventOrdersExportData(filterData.event_id, search);
      setOrdersExportData(response);
    } catch (error) {
      setOrdersExportData([]);
      console.log("Faile to fetch", error);
    }
    setIsLoading(false);
  }

  const getOrderItemsExportData = async (exportCase = 'items') => {
    exportCase === 'items' ? setOrderItemsExportData([]) : setOrdersForControlExportData([]);
    setIsLoading(true);
    try {
      const filterDataQueryString = new URLSearchParams({
        from_date: filterData.from_date,
        to_date: filterData.to_date,
        purchased_from_date: filterData.purchased_from_date,
        purchased_to_date: filterData.purchased_to_date,
        order_type: filterData.order_type
      }).toString();
      const response = 
        filterData.order_type === 'shopping' 
        ? await orderAPI.getShopOrderItemsExportData(filterDataQueryString)
        : filterData.event_id && await orderAPI.getEventOrderItemsExportData(filterData.event_id, filterDataQueryString);
      
      exportCase === 'items' ? setOrderItemsExportData(response) : _handleForControlData(response)
    } catch (error) {
      setOrderItemsExportData([]);
      setOrdersForControlExportData([]);
      console.log("Faile to fetch", error);
    }
    setIsLoading(false);
  }

  const _handleForControlData = (exportedData) => {
    const filteredExportData = exportedData.map(data => {
      const filteredArr = [];
      Object.keys({...INITIAL_FOR_CONTROL_DATA}).forEach(function(key) {
        filteredArr.push(data[key]);
      });
      return filteredArr;
    })
    
    const dateNote = (query.get('purchased_from_date') && query.get('purchased_to_date')) 
      ? `Từ 00:00:00 ${moment(query.get('purchased_from_date')).format("DD/MM/YYYY")} đến 23:59:59 ${moment(query.get('purchased_to_date')).format("DD/MM/YYYY")}` 
      : `Từ 00:00:00 ${moment(exportedData.slice(-1)[0].created_at).format("DD/MM/YYYY")} đến 23:59:59 ${moment().format("DD/MM/YYYY")}`;
    setOrdersForControlExportData([
      ...FOR_CONTROL_DATA_HEADER,
      [dateNote],
      ...FOR_CONTROL_DATA_LABELS,
      ...filteredExportData
    ]);
  }

  const _handleFilterDateRangeChange = (value) => {
    const updatedFromDate = value ? value[0].format("YYYY-MM-DD") : '';
    const updatedToDate = value ? value[1].format("YYYY-MM-DD") : '';
    setFilterData({
      ...filterData,
      from_date: updatedFromDate,
      to_date: updatedToDate
    });
  }

  const _handleFilterPaymentDateRangeChange = (value) => {
    const updatedFromDate = value ? value[0].format("YYYY-MM-DD") : '';
    const updatedToDate = value ? value[1].format("YYYY-MM-DD") : '';
    setFilterData({
      ...filterData,
      purchased_from_date: updatedFromDate,
      purchased_to_date: updatedToDate
    });
  }


  const _handleFilterDataChange = (key, value) => {
    setFilterData({
      ...filterData,
      [key]: value || ''
    })
  }

  const _handlePageChange = (page) => {
    getOrders(page);
  }

  const _handleSubmitFilter = async () => {
    props.history.replace({
      pathname: pathname,
      search: "?" + new URLSearchParams({
        ...filterData,
      }).toString()
    });
  }

  function colorOrderStatus(status) {
    switch (status) {
      case 'doi_thanh_toan':
        return '#1890ff';
      case 'dat_hang_thanh_cong':
        return '#52c41a';
      case 'hoan_thanh':
        return '#5b8c00';
      default:
        return '#000000'
    }
  }

  return (
    <Layout>
      <div className="example-input">
        <RangePicker
          style={{ margin: '0 0 20px 20px' }}
          value={[filterData.from_date ? moment(filterData.from_date) : "", filterData.to_date ? moment(filterData.to_date) : ""]}
          onChange={e => _handleFilterDateRangeChange(e)}
          bordered={true}
        />
        <RangePicker
          key="purchased_date"
          style={{ margin: '0 0 20px 20px', width: 400, maxWidth: "100%" }}
          placeholder={["Payment start date", "Payment end date"]}
          value={[filterData.purchased_from_date ? moment(filterData.purchased_from_date) : "", filterData.purchased_to_date ? moment(filterData.purchased_to_date) : ""]}
          onChange={e => _handleFilterPaymentDateRangeChange(e)}
          bordered={true}
        />
        <Input
          placeholder="Mã đơn hàng"
          value={get(filterData, 'order_code', '')}
          onChange={(e) => _handleFilterDataChange('order_code', e.target.value)}
        />
        <Input
          placeholder="Số điện thoại"
          value={get(filterData, 'buyer_phone', '')}
          onChange={(e) => _handleFilterDataChange('buyer_phone', e.target.value)}
        />
        <Select
          showSearch
          allowClear
          placeholder="Sự kiện"
          className={classes.paymentMethod}
          style={{ width: 300 }}
          value={isEmpty(filterData.event_id) ? null : filterData.event_id}
          onChange={(value) => _handleFilterDataChange('event_id', value)}
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          // filterSort={(optionA, optionB) =>
          //   optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          // }
        >
          {events.map((event) => (
            <Option key={event.id} value={event.id}>{event.name_en}</Option>
          ))}
        </Select>

        <Select
          allowClear
          className={classes.paymentMethod}
          style={{ width: 200 }}
          placeholder="Phương thức thanh toán"
          value={isEmpty(filterData.payment_method) ? null : filterData.payment_method}
          onChange={(value) => _handleFilterDataChange('payment_method', value)}
        >
          <Option value="credit">Thẻ tín dụng (Credit)</Option>
          <Option value="credit_global">Thẻ tín dụng phát hành ngoài VN</Option>
          <Option value="atm">Thẻ ngân hàng (ATM) </Option>
          <Option value="zalopay">ZaloPay </Option>
          <Option value="viet_qr">Viet QR </Option>
          <Option value="vnpay">QRCode </Option>
          <Option value="shopeepay">ShopeePay </Option>
          <Option value="bank_transfer">Chuyển khoản ngân hàng</Option>
          <Option value="voucher">Voucher</Option>
          <Option value="momo">Momo</Option>
          <Option value="partner_vnpay">Partner Vnpay</Option>
          <Option value="vn_pay_b">VNPAY</Option>
          <Option value="bnpl">Buy now pay later</Option>
          <Option value="viettel_money">Viettel Money - VietQR</Option>
          <Option value="v_atm">Viettel Money - Thẻ ATM Nội địa</Option>
          <Option value="v_credit">Viettel Money - Thẻ Quốc tế</Option>
          <Option value="payoo_atm">Payoo - Thẻ ATM nội địa</Option>
          <Option value="payoo_credit">Payoo - Thẻ Quốc tế</Option>
        </Select>

        <Select
          allowClear
          className={classes.paymentMethod}
          style={{ width: 200 }}
          placeholder="Trạng thái thanh toán"
          value={isEmpty(filterData.order_status) ? null : filterData.order_status}
          onChange={(value) => _handleFilterDataChange('order_status', value)}
        >
          <Option key="doi" value="doi_thanh_toan">Đợi thanh toán</Option>
          <Option value="dat_hang_thanh_cong">Đặt hàng thành công</Option>
          <Option value="da_huy">Đã hủy</Option>
          <Option value="hoan_tien">Hoàn tiền</Option>
          <Option value="cho_hoan_tien">Chờ hoàn tiền</Option>
        </Select>

        <Select
          allowClear
          className={classes.paymentMethod}
          style={{ width: 200 }}
          placeholder="Đơn hàng"
          value={isEmpty(filterData.order_type) ? null : filterData.order_type}
          onChange={(value) => _handleFilterDataChange('order_type', value)}
        >
          <Option value="virtual_event">Vé sự kiện</Option>
          <Option value="group_buy">Vé nhóm</Option>
          <Option value="shopping">Shop</Option>
          <Option value="virtual_limo">App</Option>
        </Select>

        <Input
          placeholder="Mã code"
          value={get(filterData, 'coupons', '')}
          onChange={(e) => _handleFilterDataChange('coupons', e.target.value)}
        />
        <Input
          placeholder="Mã giao dịch"
          value={get(filterData, 'ref_number', '')}
          onChange={(e) => _handleFilterDataChange('ref_number', e.target.value)}
          style={{width: 350, maxWidth: '100%'}}
        />
        <Select
          allowClear
          className={classes.paymentMethod}
          style={{ width: 200 }}
          placeholder="Web version"
          value={isEmpty(filterData.ui_version) ? null : filterData.ui_version}
          onChange={(value) => _handleFilterDataChange('ui_version', value)}
        >
          <Option value="new">Web mới</Option>
          <Option value="old">Web cũ</Option>
        </Select>

        <Button
          className={classes.search}
          type="primary"
          icon={<SearchOutlined />}
          onClick={_handleSubmitFilter}
        >
          Tìm kiếm
        </Button>
        {(checkPermission('list', ROLE_VALUES.EXPORT_ACTIONS) && !isEmpty(orderData.orders) && !isEmpty(query.get('event_id'))) ?
          <>
            <Button
              style={{
                backgroundColor: "#DC143C",
                height: 32,
                color: "white",
                fontSize: "13px",
                margin: '0 0 20px 16px',
                width: 'auto'
              }}
              onClick={getOrdersExportData}
            >
              Export orders
            </Button>
            {(query.get('purchased_from_date') && query.get('purchased_to_date')) ? (
              <Button
                style={{
                  backgroundColor: "#DC143C",
                  height: 32,
                  color: "white",
                  fontSize: "13px",
                  margin: '0 0 20px 16px',
                  width: 'auto'
                }}
                onClick={() => getOrderItemsExportData('for-control')}
              >
                Export đối soát
              </Button>
            ) : <></>}
            <Button
              style={{
                backgroundColor: "#DC143C",
                height: 32,
                color: "white",
                fontSize: "13px",
                margin: '0 0 20px 16px',
                width: 'auto'
              }}
              onClick={() => getOrderItemsExportData('items')}
            >
              Export order items
            </Button>

            {/* CSV */}
            <CSVLink
              data={ordersExportData}
              ref={orderCsvInstance}
              filename={"orders.csv"}
              className="btn"
              style={{
                backgroundColor: "#DC143C",
                width: "100%",
                height: 32,
                color: "white",
                fontSize: "13px",
                display: 'none'
              }}
            >
              Export orders
            </CSVLink>
            <CSVLink
              data={ordersForControlExportData}
              ref={orderItemsForControlCsvInstance}
              filename={"orders.csv"}
              className="btn"
              style={{
                backgroundColor: "#DC143C",
                width: "100%",
                height: 32,
                color: "white",
                fontSize: "13px",
                display: 'none'
              }}
            >
              Export đối soát
            </CSVLink>
            <CSVLink
              data={orderItemsExportData}
              ref={orderItemsCsvInstance}
              filename={"order-items.csv"}
              className="btn"
              style={{
                backgroundColor: "#DC143C",
                width: "100%",
                height: 32,
                color: "white",
                fontSize: "13px",
                display: 'none'
              }}
            >
              Export order items
            </CSVLink>
          </> : null}
      </div>
      <table className="table table-hover">
        <tbody>
          <tr className={classes.backgroundTr}>
            <th scope="col" className={classes.backgroundTh}>No</th>
            <th scope="col" className={classes.backgroundTh}>Mã đơn hàng</th>
            <th scope="col" className={classes.backgroundTh}>SĐT / Email</th>
            <th scope="col" className={classes.backgroundTh}>Sản phẩm</th>
            <th scope="col" className={classes.backgroundTh}>Số lượng</th>
            <th scope="col" className={classes.backgroundTh}>Sự kiện</th>
            <th scope="col" className={classes.backgroundTh}>Trạng thái đơn hàng</th>
            <th scope="col" className={classes.backgroundTh}>Code đã sử dụng</th>
            <th scope="col" className={classes.backgroundTh}>Ngày mua</th>
            <th scope="col" className={classes.backgroundTh}>Ngày sử dụng</th>
          </tr>
          {orderData.orders.map((order, index) => {
            return (
              <tr key={order.code}>
                {
                  orderData.paging.current_page === 1 ?
                    <td className={classes.backgroundTd}>
                      <span> {index + 1} </span>
                    </td>
                    :
                    <td className={classes.backgroundTd}>
                      <span> {(30 * (orderData.paging.current_page - 1)) + (index + 1)} </span>
                    </td>
                }

                <td className={classes.backgroundTd} >
                  <span
                    style={{
                      color: '#1794fc',
                      fontWeight: '500',
                      cursor: 'pointer'
                    }}
                    onClick={() => _navigateOrderDetail(order.code)}
                  >
                    {order.code}
                  </span>
                </td>
                <td className={classes.backgroundTd}>
                  {get(order, 'extra.checkout_data.club_name', '') ? (
                    <div>Group: <span style={{ fontWeight: 600 }}>{get(order, 'extra.checkout_data.club_name', '')}</span></div>
                  ) : ''}
                  <div>
                    {get(order, 'buyer.phone_area_code', '') && `(+${get(order, 'buyer.phone_area_code', '')})`} {order.buyer.phone}
                    {order.order_type === "group_buy" && <span style={{ color: '#DC143C' }}> (GR) </span>}
                  </div>
                  <div> {order.buyer.email} </div>
                </td>
                <td className={classes.backgroundTd}>
                  <span> {order.item_description_vi} </span>
                </td>
                <td className={classes.backgroundTd}>
                  <span> {order.total_item} </span>
                </td>
                <td className={classes.backgroundTd}>
                  <span> {order.extra.event_name} </span>
                </td>
                <td className={classes.backgroundTd} style={{ color: `${colorOrderStatus(order.order_status)}` }}>
                  <span> {order.order_status_description_vi} </span>
                </td>

                <td className={classes.backgroundTd}>
                  <span> {order.coupons[0]} </span>
                </td>
                <td className={classes.backgroundTd}>
                  <span> {order.created_at} </span>
                </td>
                <td className={classes.backgroundTd}>
                  {
                    (order.extra && order.extra.ticket_use_for_date) &&
                    <span> {order.extra.ticket_use_for_date} </span>
                  }
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      
      {
        (orderData.paging !== null) &&
        <div>Số lượng đơn hàng: {orderData.paging.total_item}/{orderData.total}</div>
      }
      <div className={classes.pagination}>
        {orderData.paging !== null && orderData.paging.total_item >= 30 ?
          <Pagination
            hideFirstLastPages
            pageRangeDisplayed={7}
            activePage={orderData.paging.current_page}
            itemsCountPerPage={30}
            totalItemsCount={orderData.paging.total_item}
            onChange={(page) => _handlePageChange(page)}
            itemClass="page-item"
            linkClass="page-link"
          />
          : null}
      </div>
      <Loader isOpen={isLoading} />
      <PermissionDeniedModal
        isOpen={isShowPermissionDeniedModal}
        toggle={() => setIsShowPermissionDeniedModal(!isShowPermissionDeniedModal)}
      />
      <TextModal
        isOpen={isShowNoDataModal}
        toggle={() => setIsShowNoDataModal(!isShowNoDataModal)}
        bodyText={'No Data'}
      />
    </Layout>
  );
}
export default withRouter(Orders);
